package io.bordeauxkt.website

import kotlin.js.Date

data class Localization(
	val name: String,
	val address: String?,
	val url: String,
) {
	companion object {
		val QuatreSH = Localization("4SH",  "4SH, Le Haillan", "https://4sh.fr")
		val Betclic = Localization("Betclic", "117 Quai de Bacalan 33300 Bordeaux", "https://betclic.fr")
		val BDXIOSecondWave = Localization("la Seconde Vague BDX I/O", null, "https://guild.host/seconde-vague-bdx-i-o")
	}
}

data class SocialLinks(
	val website: String? = null,
	val gitlab: String? = null,
	val github: String? = null,
	val bluesky: String? = null,
	val linkedin: String? = null,
	val mastodon: String? = null,
	val youtube: String? = null,
)

data class Speaker(
	val name: String,
	val bio: String,
	val imageUrl: String,
	val socialLinks: SocialLinks,
) {
	companion object {
		val IvanCanet = Speaker(
			name = "Ivan “CLOVIS” Canet",
			bio = "Fullstack 100% Kotlin, auteur de bibliothèques open source via OpenSavvy. Développeur et formateur chez 4SH. Pour les DSLs, contre la magie. Contributeur à Arrow et actif sur les forums.",
			imageUrl = "",
			socialLinks = SocialLinks(
				website = "https://ivan.canet.dev/",
				gitlab = "https://gitlab.com/clovis-ai",
				github = "https://github.com/clovis-ai",
				bluesky = "https://bsky.app/profile/ivcanet.bsky.social",
				linkedin = "https://www.linkedin.com/in/ivan-canet/",
			)
		)

		val SalomonBrys = Speaker(
			name = "Salomon Brys",
			bio = "Jetbrains Kotlin Certified Trainer, Google Kotlin Developer Expert, prof de danse Rock et passionné de jeux de sociétés • Créateur de Kodein",
			imageUrl = "",
			socialLinks = SocialLinks(
				github = "https://github.com/SalomonBrys/",
				linkedin = "https://www.linkedin.com/in/salomonbrys/?originalSubdomain=fr",
			)
		)

		val FredericDubuisson = Speaker(
			name = "Frédéric Dubuisson",
			bio = "Frédéric est dans le développement depuis plus de 20 ans, avec des débuts sur mobile (J2ME), beaucoup de backend Java (Spring) avant de se convertir à Kotlin. À Betclic depuis 2022, il développe et gère la plateforme Betting de prise de pari sportif.",
			imageUrl = "",
			socialLinks = SocialLinks(
				linkedin = "https://www.linkedin.com/in/fr%C3%A9d%C3%A9ric-dubuisson-283555",
				github = "https://github.com/fdubuisson",
			)
		)

		val AurelienRicheton = Speaker(
			name = "Aurélien Richeton",
			bio = "Aurélien a débuté sa carrière en 2015 en tant que développeur backend Java (Spring Boot). En 2019, son arrivée chez Betclic marque sa découverte et son adoption de Kotlin. Depuis, il conçoit, délivre et maintient des applications Kotlin pour les équipes Sport, puis plus récemment pour Casino.",
			imageUrl = "",
			socialLinks = SocialLinks(
				linkedin = "https://www.linkedin.com/in/aurelienricheton",
				github = "https://github.com/aricheton",
			)
		)

		val AntonArhipov = Speaker(
			name = "Anton Arhipov",
			bio = "Developer Advocate chez JetBrains, Anton partage ses connaissances sur Kotlin, IntelliJ IDEA et les outils IAs. Java Champion depuis 2014, il se spécialise sur les outils de développement et les nouvelles technologies.",
			imageUrl = "",
			socialLinks = SocialLinks(
				website = "https://sessionize.com/antonarhipov/",
				github = "https://github.com/antonarhipov",
				bluesky = "https://bsky.app/profile/antonarhipov.bsky.social",
				linkedin = "https://www.linkedin.com/in/antonarhipov",
				youtube = "https://www.youtube.com/@AntonArhipov",
			)
		)
	}
}

data class Talk(
	val title: String,
	val recordingUrl: String? = null,
	val speakers: List<Speaker>,
)

data class Event(
	val at: Date,
	val localization: Localization,
	val coHosts: List<Localization>,
	val eventUrl: String? = null,
	val talks: List<Talk>,
)

val Events = listOf(
	Event(
		at = Date("2024-10-22T18:45:00"),
		localization = Localization.QuatreSH,
		coHosts = emptyList(),
		eventUrl = "https://www.eventbrite.fr/e/billets-compose-multiplatform-etat-de-lart-cas-dusages-et-limites-1018664130837?aff=oddtdtcreator",
		talks = listOf(
			Talk(
				title = "Kotlin : au délà de la JVM",
				recordingUrl = "https://www.youtube.com/watch?v=PZWcDLomDS4",
				speakers = listOf(Speaker.IvanCanet),
			),
			Talk(
				title = "Compose Multiplatform : État de l'art, cas d'usage et limites",
				recordingUrl = "https://www.youtube.com/watch?v=YFmkr8Uv5rI",
				speakers = listOf(Speaker.SalomonBrys),
			)
		)
	),
	Event(
		at = Date("2025-03-13T19:00:00"),
		localization = Localization.Betclic,
		coHosts = listOf(Localization.BDXIOSecondWave),
		eventUrl = "https://www.eventbrite.fr/e/billets-ktor-notre-pari-gagnant-ktmongo-le-futur-de-mongodb-en-kotlin-1253771216459",
		talks = listOf(
			Talk(
				title = "KtMongo : premier regard sur le futur de MongoDB en Kotlin",
				recordingUrl = null,
				speakers = listOf(Speaker.IvanCanet),
			),
			Talk(
				title = "Ktor : notre pari gagnant",
				recordingUrl = null,
				speakers = listOf(Speaker.FredericDubuisson, Speaker.AurelienRicheton),
			)
		)
	),
	Event(
		at = Date("2025-04-09T19:00:00"),
		localization = Localization.QuatreSH,
		coHosts = emptyList(),
		eventUrl = "https://www.eventbrite.fr/e/kotlin-the-new-and-noteworthy-tickets-1302248633719?aff=oddtdtcreator",
		talks = listOf(
			Talk(
				title = "Kotlin, the new and noteworthy",
				recordingUrl = null,
				speakers = listOf(Speaker.AntonArhipov),
			),
		)
	),
).sortedBy { it.at.getTime() }
