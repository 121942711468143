package io.bordeauxkt.website.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import io.bordeauxkt.website.components.*
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb

@Page
@Composable
fun HomePage() {
    Column(BasicStyle.toModifier()) {
        TopBar()
        HeroSection()
        NextEvents()
        Speakers()
        WhoAreWe()
        SponsorUs()
        PastEvents()
    }
}

val BasicStyle = CssStyle {
    base {
        Modifier
            .fillMaxSize()
            .fontSize(FontSize.Large)
            .padding(16.px)
            .gap(64.px)
            .backgroundColor(rgb(22, 22, 25))
            .fontFamily("JetBrains Sans", "Inter", "system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Droid Sans", "Helvetica Neue", "Arial", "sans-serif")
    }

    Breakpoint.SM {
        Modifier
            .padding(32.px, 64.px)
    }
}
