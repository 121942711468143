package io.bordeauxkt.website.components.cosmetic

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text

@Composable
fun Section(
	title: String,
	content: @Composable () -> Unit,
) {
	Column {
		H2 { Text(title) }

		content()
	}
}
