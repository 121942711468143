package io.bordeauxkt.website.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.fontSize
import com.varabyte.kobweb.compose.css.fontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.gap
import io.bordeauxkt.website.Event
import io.bordeauxkt.website.Events
import io.bordeauxkt.website.Speaker
import io.bordeauxkt.website.components.cosmetic.Card
import io.bordeauxkt.website.components.cosmetic.CardContainer
import io.bordeauxkt.website.components.cosmetic.Section
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import kotlin.js.Date

@Composable
fun NextEvents() = Section("Calendrier") {
	val now = Date()
	val byDate = Events
		.filter { it.at.getTime() >= now.getTime() }
		.sortedBy { it.at.getTime() }

	Column(Modifier.gap(32.px)) {
		for (event in byDate) {
			NextDay(event)
		}
	}

	if (byDate.isEmpty()) {
		H3 {
			Text("Le prochain événement n'a pas encore été annoncé.")
		}

		P {
			Text("Inscrivez-vous sur ")
			A(href = "https://www.eventbrite.fr/o/bordeauxkt-29709159487") {
				Text("EventBrite")
			}
			Text(" pour être notifiés.")
		}
	}
}

@Composable
fun NextDay(event: Event) = Column {
	H3 {
		Text(event.at.toLocaleString("fr-FR"))
	}

	P {
		Text("Hébergé par ")
		A(href = event.localization.url) {
			Text(event.localization.name)
		}
		for (coHost in event.coHosts) {
			Text(" • ")
			Text("Co-organisé par ")
			A(href = coHost.url) {
				Text(coHost.name)
			}
		}
		Text(" • ")
		A(href = event.eventUrl) {
			Text("Inscription obligatoire")
		}
		Text(" (gratuite)")
	}

	CardContainer {
		for (event in event.talks) {
			Card(event.title) {
				for (speaker in event.speakers) {
					PresentedBy(speaker)
				}
			}
		}
	}
}

@Composable
private fun PresentedBy(speaker: Speaker) {
	Div({
		style {
			fontWeight(FontWeight.Lighter)
			paddingTop(0.7.em)
		}
	}) {
		Text("Présenté par ${speaker.name}")
	}

	P({
		style {
			fontSize(FontSize.Medium)
			fontWeight(FontWeight.Light)
		}
	}) {
		Text(speaker.bio)
	}

	SocialLinks(speaker.socialLinks)
}
