package io.bordeauxkt.website.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toAttrs
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun TopBar() {
	Div(TopBarStyle.toAttrs()) {
		Text("BordeauxKt")

		Div({
			style {
				display(DisplayStyle.Flex)
				flexWrap(FlexWrap.Wrap)
				gap(16.px)
			}
		}) {
			A(href = "https://www.youtube.com/@BordeauxKt") {
				Text("YouTube")
			}

			A(href = "https://www.eventbrite.fr/o/bordeauxkt-29709159487") {
				Text("Événements")
			}

			A(href = "mailto://team@bordeauxkt.io") {
				Text("Contactez-nous")
			}
		}
	}
}

val TopBarStyle = CssStyle {
	base {
		Modifier
			.fillMaxWidth()
			.fontWeight(FontWeight.Bold)
			.fontSize(FontSize.Larger)
			.display(DisplayStyle.Flex)
			.flexDirection(FlexDirection.Column)
			.gap(8.px)
	}

	Breakpoint.SM {
		Modifier
			.justifyContent(JustifyContent.SpaceBetween)
			.flexDirection(FlexDirection.Row)
	}
}
