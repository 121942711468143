package io.bordeauxkt.website.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.fontStyle
import io.bordeauxkt.website.components.cosmetic.Section
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.dom.*

@Composable
fun WhoAreWe() = Section("Qui sommes-nous ?") {
	Div({
		style {
			maxWidth(40.em)
		}
	}) {
		P {
			Text("BordeauxKt est le Kotlin user group de Bordeaux, un groupe dont l’objectif est de permettre à des acteurs et utilisateurs du langage Kotlin et son écosystème (professionnels, étudiants, universitaires, communautés open source…) de se rencontrer et d'échanger sur la métropole de Bordeaux.")
		}

		P {
			Text("Le groupe propose des présentations techniques sur les différentes utilisations de Kotlin : développement server-side, mobile, Kotlin.js ou encore Kotlin native. Ces présentations gratuites et ouvertes à tous ont lieu une fois par trimestre, dans un cadre convivial qui permet ensuite d'échanger sur les talks et sur l'écosystème.")
		}

		P({
			style {
				fontStyle(FontStyle.Italic)
			}
		}) {
			Text("Vous souhaitez venir à un événement ? Pensez à vous inscrire en prenant un billet (gratuit) de manière à ce que nous ajustions au mieux les quantités pour le buffet !")
		}

		P {
			Text("De nombreuses autres communautés existent sur Bordeaux autour de la tech. En voici quelques-unes qui pourraient vous intéresser :")
		}
		Ul {
			Li {
				A("http://bordeauxjug.org/") { Text("BordeauxJUG") }
				Text(", le groupe Java")
			}
			Li {
				A("https://www.meetup.com/fr-FR/gdg-bordeaux/") { Text("GDG Bordeaux") }
				Text(", le groupe des technologies Google, incluant Android")
			}
			Li {
				A("https://www.meetup.com/fr-FR/bordeauxjs/") { Text("BordeauxJS") }
				Text(", le groupe JavaScript")
			}
			Li {
				A("https://cocoaheads-bordeaux.notion.site/") { Text("Cocoaheads Bordeaux") }
				Text(", le groupe autour des technologies Apple")
			}
		}

		P {
			Text("Notre objectif est de nous concentrer sur Kotlin, dans tous ses cas d'usage (server-side, Android, multiplatform, web…).")
		}
	}
}
