package io.bordeauxkt.website.components

import androidx.compose.runtime.Composable
import io.bordeauxkt.website.Events
import io.bordeauxkt.website.components.cosmetic.Section
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul
import kotlin.js.Date

@Composable
fun PastEvents() = Section("Événements passés") {
	val now = Date()
	val talks = Events
		.toList()
		.filter { it.at.getTime() < now.getTime() }
		.sortedBy { -it.at.getTime() }
		.flatMap { it.talks }

	Ul {
		for (talk in talks) {
			Li {
				Text(talk.title)

				if (talk.recordingUrl != null) {
					Text(" — ")
					A(talk.recordingUrl) {
						Text("Voir l'enregistrement")
					}
				}
			}
		}
	}
}
