package io.bordeauxkt.website.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import io.bordeauxkt.website.Events
import io.bordeauxkt.website.Speaker
import io.bordeauxkt.website.components.cosmetic.Card
import io.bordeauxkt.website.components.cosmetic.CardContainer
import io.bordeauxkt.website.components.cosmetic.Section
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun Speakers() = Section("Speakers") {
	val speakers = remember {
		Events
			.flatMap { it.talks }
			.flatMap { it.speakers }
			.sortedBy { it.name }
			.distinct()
	}

	CardContainer {
		for (speaker in speakers) {
			Speaker(speaker)
		}
	}

	P {
		Text("Un sujet qui vous tient à cœur, vous souhaitez nous partager une expérience ? ")
		A(href = "mailto://team@bordeauxkt.io") {
			Text("Contactez-nous !")
		}
	}
}

@Composable
private fun Speaker(speaker: Speaker) {
	Card(speaker.name) {
		Text(speaker.bio)
		SocialLinks(speaker.socialLinks)
	}
}
