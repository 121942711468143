package io.bordeauxkt.website.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun HeroSection() {
	Box(HeroSectionStyle.toModifier(), contentAlignment = Alignment.Center) {
		Column(HeroSectionColumnStyle.toModifier(), horizontalAlignment = Alignment.CenterHorizontally) {
			P(Modifier.fontSize(FontSize.XXLarge).fontWeight(FontWeight.Bold).toAttrs()) {
				Text("BordeauxKt")
			}
			P(Modifier.fontSize(FontSize.Larger).toAttrs()) {
				Text("Le user group Kotlin de Bordeaux")
			}
			P {
				Text("BordeauxKt est un user group au service du langage Kotlin et son écosystème, à destination des développeurs de Bordeaux et sa métropole.")
			}
			P {
				Text("Nous organisons des soirées autour de présentations techniques pour discuter des différentes utilisations de Kotlin : développement côté serveur, mobile, web, ou encore natif.")
			}
		}
	}
}

val HeroSectionStyle = CssStyle {
	base {
		Modifier
			.fillMaxWidth()
			.minHeight(30.vh)
	}
}

val HeroSectionColumnStyle = CssStyle {
	base {
		Modifier
	}
}
