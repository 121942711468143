package io.bordeauxkt.website.components.cosmetic

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.fontWeight
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Hr
import org.jetbrains.compose.web.dom.Text

@Composable
fun Card(
	title: String,
	content: @Composable () -> Unit,
) {
	Div({
		style {
			backgroundColor(rgb(43, 43, 46))
			padding(1.em)
			paddingTop(2.em)
			borderRadius(.5.em)
			display(DisplayStyle.Flex)
			flexDirection(FlexDirection.Column)
			gap(.5.em)
			minWidth(18.em)
			maxWidth(22.em)
			flexGrow(1)
		}
	}) {
		Div({
			style {
				fontWeight(FontWeight.Bold)
				property("height", "2.5lh")
			}
		}) {
			Text(title)
		}

		Delimiter()

		content()
	}
}

@Composable
fun CardContainer(content: @Composable () -> Unit) {
	Div({
		style {
			display(DisplayStyle.Flex)
			flexDirection(FlexDirection.Row)
			gap(1.em)
			flexWrap(FlexWrap.Wrap)
		}
	}) {
		content()
	}
}

@Composable
private fun Delimiter() {
	Hr {
		style {
			width(100.percent)
		}
	}
}
