package io.bordeauxkt.website.components

import androidx.compose.runtime.Composable
import io.bordeauxkt.website.components.cosmetic.Section
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun SponsorUs() = Section("Pourquoi nous sponsoriser ?") {
	Div({
		style {
			maxWidth(40.em)
		}
	}) {
		P {
			Text("Les soirées BordeauxKt sont organisées chez des entreprises partenaires. Nous cherchons à créer des échanges de connaissances entre diverses entreprises pour permettre à la communauté au sens large de profiter des avancées de chaque entreprise.")
		}

		P {
			Text("Nous cherchons des sponsors pour accueillir régulièrement nos événements, une fois par trimestre.")
		}

		P {
			Text("Nous demandons aux sponsors de prendre en charge l'accueil des participants, le défraiement des speakers si besoin et de fournir la matériel d'enregistrement.")
		}

		P {
			Text("Si vous souhaitez sponsoriser nos événements, ")
			A("mailto://team@bordeauxkt.io") {
				Text("contactez-nous")
			}
			Text(".")
		}
	}
}
