package io.bordeauxkt.website.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.silk.components.icons.fa.*
import io.bordeauxkt.website.SocialLinks
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.dom.A

@Composable
fun SocialLinks(links: SocialLinks) = Row(Modifier.gap(.3.em)) {
	SocialLink(links.website) { FaGlobe() }
	SocialLink(links.gitlab) { FaGitlab() }
	SocialLink(links.github) { FaGithub() }
	SocialLink(links.bluesky) { FaBluesky() }
	SocialLink(links.youtube) { FaYoutube() }
	SocialLink(links.linkedin) { FaLinkedin() }
	SocialLink(links.mastodon) { FaMastodon() }
}

@Composable
private fun SocialLink(
	url: String?,
	icon: @Composable () -> Unit,
) {
	if (url != null) {
		A(href = url) {
			icon()
		}
	}
}
